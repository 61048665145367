/* XONE TOGGLER - Stylesheet */
/* Based on: https://codepen.io/Catagen/pen/PqYdXR */

#xonetoggler {
  position: relative;
  color: #1d002f;
	background: linear-gradient(to bottom,rgba(11, 218, 227,1) 0%, rgba(11, 218, 227,1) 3%, rgba(11, 218, 227,0) 74%,  rgba(11, 218, 227,1) 97%, rgba(11, 218, 227,1) 100%);
  -webkit-box-shadow: 0 0 74px -5px rgba(11, 218, 227, 0.65);
  box-shadow: 0 0 74px -5px rgba(11, 218, 227, 0.65);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-top: 5px solid #00c4cc;
  border-bottom: 5px solid #00c4cc;
}
#xonetoggler.xt-night-full {
  color: #00c4cc;
  background: linear-gradient(to bottom,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 3%, rgba(29,0,47,0) 74%, rgba(29,0,47,1) 97%, rgba(29,0,47,1) 100%);
  -webkit-box-shadow: 0 0 74px -5px rgba(155, 20, 128, 1);
  box-shadow: 0 0 74px -5px rgba(155, 20, 128, 1);
  border-top: 5px solid #6e0c5e;
  border-bottom: 5px solid #6e0c5e;
}

.xt-hero-bg {
  background-color: #1d002f;
  background: url(../assets/images/grungy-grid.jpg) top center; 
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  z-index: 0;
  /* -webkit-box-shadow: 0 0 47px -5px rgba(0, 0, 0, 1) inset;
  box-shadow: 0 0 47px -5px rgba(0, 0, 0, 1) inset; */
}
/* TO-DO: Add day-night video clips :: 
.xt-hero-bg .clip-hide {display: none !important;}
.xt-hero-clip-night, .xt-hero-clip-day {  
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.xt-hero-clip-night { background-color: #1d002f; opacity: 0.88; }
.xt-hero-clip-day { background-color: #00c4cc; opacity: 0.88; }
*/

#xt-info {
  position: absolute;
  left: 5%;
}
.xt-section {
	padding-top: 40px;
	font-size: 1em;
	text-align: center;
	width: 100%;
	height: auto;
  position: relative;
  z-index: 1;
}
.xt-night .xt-section {
  background: transparent;
}
.xt-night #xt-intro-text {
	color: #e9e5d5;
}
.xt-time-circle {
  position: relative;
  background: linear-gradient(to bottom, #BE4405, #F6C60C);
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 4.5px solid #555;
  overflow: hidden;
  padding: 0px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.4);
  text-align: center;

  -webkit-transition: all 1.1s ease-in-out;
		transition: all 1.1s ease-in-out;
}
.xt-night .xt-time-circle{
  background: linear-gradient(to bottom, #111936, #285A7B);
  border-color: #e9e5d5;
}
.xt-sun {
  margin-top: 20px;
  background: #F2EF88;
  box-shadow: 0 0 20px rgba(242, 239, 136, 0.4);
  width: 30%;
  height: 30%;
  border-radius: 50%;
  position: relative;
  left: 35%;
  top: 48%;

  -webkit-animation: pulse 5s ease infinite alternate;
	animation: pulse 5s ease infinite alternate;
  -webkit-transition: all 1.1s 0.4s ease;
		transition: all 1.1s 0.4s ease;
}
@keyframes pulse {
    0% {box-shadow: 0 0 20px rgba(242, 239, 136, 0.4);}
    50% {box-shadow: 0 0 40px rgba(242, 239, 136, 1);}
    100% {box-shadow: 0 0 20px rgba(242, 239, 136, 0.4);}
}
@-webkit-keyframes pulse {
    0% {box-shadow: 0 0 20px rgba(242, 239, 136, 0.4);}
    50% {box-shadow: 0 0 40px rgba(242, 239, 136, 1);}
    100% {box-shadow: 0 0 20px rgba(242, 239, 136, 0.4);}
}
.xt-night .xt-sun {
  top: 70%;
  left: 20%;

  -webkit-transition: all 1.1s 0s ease;
		  transition: all 1.1s 0s ease;
}
.xt-moon {
  position: absolute;
  margin-top: 20px;
  background: #d9d8d0;
  box-shadow: inset -10px 2px 0 0px #899098;
  width: 22.5%;
  height: 22.5%;
  border-radius: 100%;
  position: relative;
  left: 120%;
  margin-left: -50px;
  top: -50%;
  overflow: hidden;

  -webkit-transition: all 1.1s 0s ease;
		transition: all 1.1s 0s ease;
}
.xt-night .xt-moon {
  left: 65%;
  top: -10%;

  -webkit-transition: all 1.1s 0.4s ease;
		  transition: all 1.1s 0.4s ease;
}
.xt-moon div:nth-child(n) {
  position: relative;
  background: #b9b8b0;
  border-radius: 50%;
  box-shadow: inset 4px -2px 0 0px #535457;
}
.xt-moon div:nth-child(1) {
  top: 25%;
  left: 12%;
  width: 27%;
  height: 27%;
}
.xt-moon div:nth-child(2) {
  top: -11%;
  left: 60%;
  width: 16%;
  height: 16%;
}
.xt-moon div:nth-child(3) {
  top: 25%;
  left: 44%;
  width: 16%;
  height: 16%;
}
.xt-stars {
  opacity: 0;
  margin-left: 100px;
  top: 0;
  left: 0;
  -webkit-transition: all 0.8s 0s ease;
		transition: all 0.8s 0s ease;
  z-index: 1000;
}
.xt-night .xt-stars {
  opacity: 1;

  -webkit-transition: all 3s 0.4s ease;
		transition: all 3s 0.4s ease;
}
.xt-stars div:nth-child(n) {
  position: absolute;
  background: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  overflow: hidden;
  width: 0.4%;
  height: 0.4%;

  margin-left: 20%;
  margin-top: -20%;

  -webkit-transition: all 1s 0s ease;
		transition: all 1s 0s ease;
}
.xt-night .xt-stars div:nth-child(n) {
  margin-left: 0;
  margin-top: 0;

  -webkit-transition: all 1s 0.4s ease-out;
		transition: all 1s 0.4s ease-out;  
}
.xt-stars div:nth-child(1) {
  left: 25%;
  top: 30%;
}
.xt-stars div:nth-child(2) {
  left: 55%;
  top: 20%;
}
.xt-stars div:nth-child(3) {
  left: 60%;
  top: 60%;
}
.xt-stars div:nth-child(4) {
  left: 40%;
  top: 45%;
}
.xt-stars div:nth-child(5) {
  left: 15%;
  top: 55%;
}
.xt-stars div:nth-child(6) {
  left: 40%;
  top: 10%;
}
.xt-stars div:nth-child(7) {
  left: 80%;
  top: 55%;
}
.xt-water {
  position: absolute;
  background: repeating-radial-gradient(ellipse farthest-corner at center -400%, #f5c30e 30%, #518eac 90%) repeat scroll 0 0 #518eac;
  width: 100%;
  height: 200px;
  top: 68%;

  -webkit-transition: all 1.1s ease;
		transition: all 1.1s ease;

}
.xt-night .xt-water{
  background: repeating-radial-gradient(ellipse farthest-corner at center -400%, #7fa1bb 30%, #1d425a 90%) repeat scroll 0 0 #1d425a;
}
#xt-switch,
#xt-circle {
  height: 30px;
  -webkit-transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
          transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
} 
#xt-switch {
  width: 60px;
  margin: 0 auto;
  border: 2px solid #DA8508;
  border-radius: 27px;
  background: #DA8508;
  position: relative;
}
#xt-circle {
  margin-top: 5%;
  margin-left: 5%;
  width: 40%;
  height: 80%;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
  background: #e9e5d5;
}
.xt-switched {
  border-color: #1C3958 !important;
  background: #1C3958 !important;
}
.xt-switched #xt-circle {
  margin-left: 55%;
  background: #e9e5d5;
}
