/* ##################################### */
/* ####### APP.TSX CUSTOM STYLES ####### */
/* ##################################### */

/* Optimization */
html {
  text-rendering: optimizelegibility;
  font-feature-settings: "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
}

/* Wallet Connect Dialog Box */
.MuiDialogTitle-root, #mball-minter-v1 .minter-header {
  background: -webkit-linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
  background: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
  background-color: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
  background-color: -webkit-linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
  background-image: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%) !important;
}
.MuiDialogTitle-root .MuiSvgIcon-root {color: #eeeeee;}
.MuiButton-label {font-size: 1.3rem !important; font-family: "Dosis", sans-serif !important;}
  
p.MuiTypography-colorTextSecondary {color: rgb(255,255,255);}
p.MuiTypography-body1 {font-family: "Dosis", sans-serif !important;}

/* iphone feature-mob */

/* General Customizations */

.from-transparent { background-image: radial-gradient(transparent,rgb(20,0,38),var(rgb(20,0,38),rgb(17 17 17/0))); }

.bg-purp1 { background-color: #1D002F !important; }
.bg-purp2 { background-color: #380b55 !important; }
.bg-purp-grad { background: linear-gradient(-45deg, #1D002F 0%,#380b55 100%); }
.bg-green1 { background-color: #22c777 !important; background: #22c777 !important; }

.bg-purp-opaque { background-color: rgba(29,0,47, 0.56) !important; background: rgba(29,0,47, 0.56) !important; }
.bg-green-opaque { background-color: rgba(0, 47, 27, 0.56) !important; background: rgba(0, 47, 27, 0.56) !important; }
.bg-blue-opaque { background-color: rgba(0, 28, 47, 0.56) !important; background: rgba(0, 28, 47, 0.56) !important; }
.bg-pink-opaque { background-color: rgba(47, 0, 47, 0.56) !important; background: rgba(47, 0, 47, 0.56) !important; }
.bg-yellow-opaque { background-color: rgba(47, 38, 0, 0.56) !important; background: rgba(47, 38, 0, 0.56) !important; }

.bxs-purp1 {
  -webkit-box-shadow: 0 0 74px -5px rgba(155, 20, 128, 0.92);
  box-shadow: 0 0 74px -5px rgba(155, 20, 128, 0.92); }

.bxs-blue1 { 
  -webkit-box-shadow: 0 0 74px -5px rgba(11, 218, 227, 0.65);
  box-shadow: 0 0 74px -5px rgba(11, 218, 227, 0.65); }

.bxs-purp2 {  box-shadow: 0 0 11px -2px rgba(101, 11, 88, 0.95); }
.bxs-blue2 { box-shadow: 0 0 38px -5px #00C4CC; }
.bxs-blue3 { box-shadow: 0 0 11px -2px #22c777; }

.bxs-green2 { box-shadow: 0 0 38px -5px #22c777; }
.bxs-green3 { 
  -webkit-box-shadow: 0 0 1rem -5px #22c777;
  box-shadow: 0 0 1rem -5px #22c777; }

  .bxs-blue-card { box-shadow: 0 0 0.83rem 0 rgba(0, 196, 204, 0.65); }

/* .card-shadow { box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1); } */
.card-shadow { box-shadow: 0 0 1.2rem 0 rgba(20, 0, 38, 0.5); }
.card-shadow-lg { box-shadow: 0 -3px 2.5rem 7px rgb(20 0 38 / 65%) !important }
/* .tech-card {background: transparent; border: 2px solid #22c777;} */

.subtext {
  font-size: 65%;
  position: relative;
  top: 0.065em;
}
.nav-link .subtext { color:rgba(255,255,255,1); }

.btn-hover {
  transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -webkit-transition: all 0.15s ease;
}
.btn-hover:hover {
  transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
}

.img-hover {
	-webkit-transition: 0.2s ease-in;
   -o-transition: 0.2s ease-in;
   transition: 0.2s ease-in;
}
.img-hover:hover {
	-webkit-transform: scale(1.1);
   -ms-transform: scale(1.1);
   transform: scale(1.1);
}

div.bg-overlay {position: fixed; left:0; top: 0; z-index: -1; width:100%; height:100%; background:radial-gradient(ellipse farthest-corner at 50% 70%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7));}
.content-wrapper section {border-radius: var(--card-border-radius);}

.bg-gradient-white-tr { 
  background: linear-gradient(-11deg, rgba(255,255,255,0.3), rgba(255,255,255,0.65), rgba(255,255,255,0.83), rgba(255,255,255,0.92)) !important;
}
.bg-gradient-purple-tr { 
  background: linear-gradient(-11deg, rgba(88, 0, 254, 0.3), rgba(88, 0, 254,0.65), rgba(88, 0, 254,0.83), rgba(88, 0, 254,0.92)) !important;
}
.bg-blue-gradient-tr {
  background-color: #188ef4;
  background: linear-gradient(to top right, rgba(24, 142, 244,0.5) 0%, rgba(120, 44, 207,0.5) 90%);
  background: -webkit-linear-gradient(to top right, rgba(24, 142, 244,0.5) 0%, rgba(120, 44, 207,0.5) 90%);
  background: -o-linear-gradient(to top right, rgba(24, 142, 244,0.5) 0%, rgba(120, 44, 207,0.5) 90%);
}

.logo-title {
  font-weight: 700;
  font-size: calc(1rem + 1vw);
  text-shadow: 0 0 5px rgb(0,0,0);
  letter-spacing: 0.03em;
  animation: all 0.1s linear;
}

.cl-nav-logo {
/*   image-rendering: optimizeQuality;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated; */
  image-rendering: optimizeSpeed;
  filter: drop-shadow(0 0 0.1em rgba(255,255,255,0.65));
  /* animation: 1s linear logopop; */
  animation: all 0.1s linear;
}

@media screen and (min-width: 576px) {
.theme-nav.darkHeader .navbar-brand .cl-nav-logo {height: 38px;}
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem #00C4CC;
}

/* #mball-main {
  background: linear-gradient(45deg, rgb(11, 146, 92), rgb(14, 101, 133), rgb(64, 15, 129), rgb(160, 39, 124), rgb(182, 7, 95));
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
} */

#link3 {
  -webkit-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#plasmagrid {
  bottom: 0;
  width: 100%;
  height: 480px;
  color: #111;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  background: url(../assets/images/neon-grid-purplebg-min.png) top -1px center, linear-gradient(180deg, transparent 0%, #1D002F 11%, #1D002F 100%);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-attachment: scroll;
  -webkit-mask-image: linear-gradient(transparent -33%, rgba(0,0,0,0.4) 29%, black 92%);
  mask-image: linear-gradient(transparent -33%, rgba(0,0,0,0.4) 29%, black 92%);
  /* -webkit-transform: rotate3d(1, 2, 1, 0deg);
  transform: rotate3d(1, 2, 1, 0deg);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center; */
}

#vid-xone {
  position: fixed;
  bottom: -7%;
  width: 100%; 
  height: 110%;
  object-fit: cover;
  background-size: cover;
  z-index: -5;
  opacity:0.92;
  @media only screen and (max-width: 768px) {
    height: 115%;
  }
}
/* 
#vidXone { position:absolute; z-index: 1; top: 0; left: 0; width:100%; }

@media (min-width: 992px) {
  #vidXone { width:100%; height:auto; }
}
@media (max-width: 991px) {
  #vidXone { width:auto; height:100%; }
}
 */

/* xone.land */
.xone-bg {
  background: radial-gradient(ellipse closest-side, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(https://cdn.glitch.global/84180755-58e4-4c80-b543-77cacf869330/xone-bg-loop.gif?v=1648567442504);
  background-size: cover;
  background-repeat: no-repeat;
/*   @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    background-image: radial-gradient(ellipse closest-side, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/brooklyn-bridge-2x.jpg);
} */
}

/* SECTION STYLES */
#hero-minter, #xtrood {
  min-height: 100vh;
  vertical-align: middle;
}

.section-h1 {
  vertical-align:middle;
  font-size: 3rem;
  letter-spacing: 0.05em;
  font-weight: 700;
  line-height: 2.8rem;
}
.xtrood-oo {
  text-shadow: 0px 3px 2px rgba(88, 38, 141, 0.88);
  animation: purple-pulse 1s ease-in-out infinite;
  -webkit-animation: purple-pulse 1s ease-in-out infinite;
  font-size: 4.1rem;
  letter-spacing: -0.19em;
  font-weight: 800;
  display: inline;
  position: relative;
  bottom: -0.12rem;
  margin: auto 0;
  margin-left: -0.04em;
  padding-right: 0.18em;
}

#hero-minter { 
  background: transparent;
/*   background: linear-gradient(-177deg,rgba(29,0,47,.55) 0%, rgba(29,0,47,0.15) 38%, rgba(29,0,47,0.15) 65%, rgba(29,0,47,0.83) 83%, rgba(29,0,47,1) 92%, rgba(29,0,47,1) 100%), url(../assets/images/grungy-grid.jpg) top -1px center;
  background-repeat: no-repeat;; 
  background-position: top center;
  background-size: cover;
  background-attachment: scroll; */
  border: none !important;
  overflow: hidden;
  position: relative;
}

.mb-hero-bg {
  background-color: #1d002f;
  background: linear-gradient(-177deg,rgba(29,0,47,.55) 0%, rgba(29,0,47,0.15) 38%, rgba(29,0,47,0.15) 65%, rgba(29,0,47,0.83) 83%, rgba(29,0,47,1) 92%, rgba(29,0,47,1) 100%), url(../assets/images/grungy-grid.jpg) top -1px center;
  background-repeat: no-repeat;; 
  background-position: top center;
  background-size: cover;
  background-attachment: scroll;
  opacity: 0.6;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

#hero-minter .minting-box {
  background-color: transparent !important;
  background: linear-gradient(-228deg, rgba(20,0,38,1), rgba(56, 0, 83,1));
  background-size: 200% 200%;
  -webkit-animation: mintbox-gradient 11s ease infinite;
  -moz-animation: mintbox-gradient 11s ease infinite;
  animation: mintbox-gradient 11s ease infinite;
}
.minter-container {
  background: url(../assets/images/neon-grid-min.png) top -1px center;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-attachment: scroll;
  -webkit-mask-image: linear-gradient(transparent -33%, rgba(0,0,0,0.4) 29%, black 92%);
  mask-image: linear-gradient(transparent -33%, rgba(0,0,0,0.4) 29%, black 92%);
}

div.bg-overlay-hero {
  position: absolute;
  height:100%;
  width:100%;
  top: 0;
  left:0;
  background-color: transparent;
  background-image: radial-gradient(transparent,rgb(20,0,38),var(rgb(20,0,38),rgb(17 17 17/0)));
}
div.bg-overlay-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  background-image: linear-gradient(180deg, #30273abd 0%, #1D002F 100%);
  opacity: 1;
  transition: all 0.3s;
}
#features {
  background: linear-gradient(175deg,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 29%, #22c777 105%); 
}
#xone-inner, #xone-inner-2 {position: relative; z-index: 3;}
.card.xonecard {
  background: radial-gradient(ellipse at top, rgba(121, 33, 165, 0.5) 0%, rgba(29,0,47, 0.5) 100%), url(https://cdn.glitch.global/f535e99a-aa99-49b0-888f-ab86e79ca548/mtbl-bg1.jpg?v=1645634708211);
  background-repeat: repeat;
  text-align: center;
}
.card.techcard {
  border-radius: 22px; 
  /*NOTE - Declare transition here! */
  animation: all 0.2s ease;
}
/* .card.techcard:hover {border-radius: 24px;} */

#nft { 
  background: linear-gradient(180deg,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 29%, rgba(29,0,47,0.92) 56%, rgba(29,0,47,0.92) 65%, #6e0c5e 100%); 
}
#plasmaverse {
  background: linear-gradient(-177deg,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 29%, rgba(29,0,47,0) 83%, rgba(29,0,47,0) 100%); 
  /* background: linear-gradient(-177deg,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 29%, rgba(29,0,47,0) 100%);  */
  /* box-shadow: 0 0 47px -5px #1d002f; */
  /* box-shadow: 0px -5px 38px 6px #44b9fc; */
}

#xtrood, #tokenomics {
  position: relative;
  /* background: linear-gradient(to bottom,rgba(29,0,47,0) 0%, rgba(29,0,47,0) 20%, rgba(29,0,47,1) 83%, rgba(29,0,47,1) 100%);  */
  /* background: linear-gradient(0deg,rgba(29,0,47,1) 0%, rgba(29,0,47,1) 100%);  */
}

/* TOKENOMICS */
.token-card-blue, .token-card-purp, .token-card-pink {border-radius: 15px;}
.token-card-blue .card-header, 
.token-card-purp .card-header, 
.token-card-pink .card-header {border-top-right-radius: 15px; border-top-left-radius: 15px;}

.token-card-blue {background-color: rgba(38, 137, 182,0.5);}
.token-card-purp {background-color: rgba(101, 38, 182,0.5);}
.token-card-pink {background-color: rgba(182, 38, 182,0.5);}
.token-card-blue .card-header {background-color: rgb(38, 137, 182); border-bottom: 1px solid rgba(255,255,255,0.65);}
.token-card-purp .card-header {background-color: rgb(101, 38, 182); border-bottom: 1px solid rgba(255,255,255,0.65);}
.token-card-pink .card-header {background-color: rgb(182, 38, 182); border-bottom: 1px solid rgba(255,255,255,0.65);}

#tokenomics .btn.btn-solscan, .btn.btn-solexplorer {
  line-height: normal; 
  letter-spacing: 0.03em; 
  animation: all 0.2s linear; }
/* .btn-solscan:hover, .btn-solexplorer:hover {transform: scaleX(1.05);} */
#mball-token .btn-solscan:hover {background-color:rgba(38, 137, 182,1); color: white; border: 1px solid rgba(38, 137, 182,1);}
#xone-token .btn-solscan:hover {background-color:rgba(101, 38, 182,1); color: white; border: 1px solid rgba(101, 38, 182,1);}
#plasma-token .btn-solscan:hover {background-color:rgba(182, 38, 182,1); color: white; border: 1px solid rgba(182, 38, 182,1);}

#mball-token .btn-learn:hover {background-color:lightgray; color: rgb(38, 137, 182); border: 1px solid rgba(38, 137, 182,1);}
#xone-token .btn-learn:hover {background-color:lightgray; color: rgb(101, 38, 182); border: 1px solid rgba(101, 38, 182,1);}
#plasma-token .btn-learn:hover {background-color:lightgray; color: rgb(182, 38, 182); border: 1px solid rgba(182, 38, 182,1);}

#section-x {
  background: radial-gradient(ellipse closest-side, rgba(51, 2, 56, 0.1), rgba(51, 2, 56, 0.47) 121%), linear-gradient(-45deg, rgb(11, 146, 92), rgb(14, 101, 133), rgb(64, 15, 129), rgb(160, 39, 124), rgb(182, 7, 95));
  background-size: 150% 150%;
  animation: gradient 15s ease infinite;
}

#team { 
  background: linear-gradient(177deg, #00C4CC 0%, #380056 83%, #380056 100%); 
  border-top: 2px solid #00f7ff;
  -webkit-box-shadow: 0 47px 121px 35px rgba(11, 218, 227, 0.74);
  box-shadow: 0 47px 121px 35px rgba(11, 218, 227, 0.74);
}
#team-members .card {border-radius: 15px;}
#team-members .card .card-img-top {border-top-right-radius: 15px; border-top-left-radius: 15px;}

.team-social-icon {
  filter: saturate(0) brightness(0.6) contrast(4) invert(0);
  /* display: flex; */
}
.team-social-icon-fa {display: flex;}
.team-social-icon, .team-social-icon-fa {margin: 1px auto; animation: all 0.1s linear;}
.team-social-icon-fa:hover { transform: scale(1.1) translateX(0) translateY(0); }
.team-social-icon:hover {filter: saturate(0.4) brightness(0.8) contrast(2) invert(0); transform: scale(1.1);}

.sep-team-roadmap { width: 100%; height: 111px; }
@media (max-width: 576px) { .sep-team-roadmap { height: 88px; } }

#team .lead { font-size: 1.4rem !important; }
#team .xtrood-logo {
  width: 360px;
  position: absolute !important;
  top: -30px;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 0.5rem crimson);
  -o-filter: drop-shadow(0 0 0.5rem crimson);
  -ms-filter: drop-shadow(0 0 0.5rem crimson);
  -moz-filter: drop-shadow(0 0 0.5rem crimson);
  -webkit-filter: drop-shadow(0 0 0.5rem crimson);
  animation: animate-shadow 1s linear infinite;
}

#roadmap {position: relative; z-index: 5; background: linear-gradient(to bottom, rgba(29,0,47,0) 0%, rgba(29,0,47,0) 11%, rgba(29,0,47,1) 85%);}
#faq-section {background: linear-gradient(to top, rgba(29,0,47,0) 0%, rgba(29,0,47,1) 47%);}

.feature-p { font-size: 1.7rem; letter-spacing: 0.06rem; font-style: italic; }
.feature-h2 { font-size: 2rem; letter-spacing: 0.06rem; font-style: italic; }

@media (max-width: 992px) {
  #team .xtrood-logo { width: 250px; }
  /* #xones { padding: 0 22px; } */
  .xtrood-img-col { margin-left: 22%; }
  .xtrood-img-col, .xtrood-img { width: 121% !important; }

  .feature-p { font-size: 1.3rem; letter-spacing: 0.04rem; }
  .feature-h2 { font-size: 1.6rem; letter-spacing: 0.04rem; }
}

/* ***** */
/* bootstrap templates */
.b-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}


/* 
.mask2 {
  -webkit-mask-image: radial-gradient(circle, rgb(255, 255, 255) 70%, rgba(255, 255, 255, 0.5) 70%);
  mask-image: radial-gradient(circle, rgb(255, 255, 255) 70%, rgba(0, 0, 0, 0.5) 70%);
} */

/* B-BALL Hero Avatar */
div.mball-avatar {
  width: auto;
  height: 121px;
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/basketball-minter-420px.gif?v=1646330542707) no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  background-size: contain;
  position: relative;
  margin-bottom: -9px;
  /* margin-bottom: calc(-1.1rem - 1px); */
  z-index: 9;
}

.gradient-txt-green {
  background: linear-gradient(45deg, #38009f 10%, #22c777 90%);
  background: -webkit-linear-gradient(45deg, #38009f 10%, #22c777 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-txt-purple {
  background: linear-gradient(45deg, indigo 30%, purple 80%);
  background: -webkit-linear-gradient(45deg, indigo 30%, purple 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-txt-pink {
  background: linear-gradient(45deg, rgb(219, 107, 16) 10%, rgb(194, 10, 87) 90%);
  background: -webkit-linear-gradient(45deg, rgb(219, 107, 16) 10%, rgb(194, 10, 87) 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-txt-light {
  background: linear-gradient(45deg, rgb(255, 255, 255) 10%, rgb(146, 146, 146) 90%);
  background: -webkit-linear-gradient(45deg, rgb(255, 255, 255) 10%, rgb(146, 146, 146) 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-text { 
  /* color: linear-gradient(45deg, rgb(77, 25, 130) 0%, rgb(121, 11, 121)100%) !important; */
  color: white;
  font-weight: 300; 
  font-style: italic;
  line-height: 2.2rem; 
  letter-spacing: 0.05em; 
  vertical-align: center;
  font-size: calc(1rem + 0.47vw + 0.47px);
}

.hero-section {
  background: radial-gradient(ellipse at bottom, rgba(6, 23, 70, 0.6) 10%, rgba(49, 5, 71, 0.6) 100%), url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mbtl-bg-trans33.png?v=1643647587600);
  background-repeat: repeat;
  height: 50vh;
}

.hero-title { 
  font-weight: 700;
  font-size: calc(1.3rem + 1.51vw + 1px);
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 1)); }

.hero-title .h1-engage {
  color: rgb(38, 137, 182);
  -webkit-animation: highlite1 2.5s infinite ease;
  -moz-animation: highlite1 2.5s infinite ease;
  -o-animation: highlite1 2.5s infinite ease;
  animation: highlite1 2.5s infinite ease;
  animation-delay: 1.1s; }
.hero-title .h1-expand {
  color: rgb(101, 38, 182);
  -webkit-animation: highlite2 2.5s infinite ease;
  -moz-animation: highlite2 2.5s infinite ease;
  -o-animation: highlite2 2.5s infinite ease;
  animation: highlite2 2.5s infinite ease;
  animation-delay: 1.1s; }
.hero-title .h1-earn {
  color: rgb(182, 38, 182);
  -webkit-animation: highlite3 2.5s infinite ease;
  -moz-animation: highlite3 2.5s infinite ease;
  -o-animation: highlite3 2.5s infinite ease;
  animation: highlite3 2.5s infinite ease;
  animation-delay: 1.1s; }

.hero-text, .hero-xone-badge {
  /*   filter: drop-shadow(0 0 0.5rem rgba(0, 196, 204,1));
    -o-filter: drop-shadow(0 0 0.5rem rgba(0, 196, 204,1));
    -ms-filter: drop-shadow(0 0 0.5rem rgba(0, 196, 204,1));
    -moz-filter: drop-shadow(0 0 0.5rem rgba(0, 196, 204,1));
    -webkit-filter: drop-shadow(0 0 0.5rem rgba(0, 196, 204,1));
    animation: animate-shadow-ocean 11s ease infinite; */
    filter: drop-shadow(0 1px 0.38rem rgb(29, 0, 38));
  }

.hero-desc {
  font-size: calc(1rem + 0.47vw + 3px);
  color: white;
  margin-right: -5px;
  font-weight: 500; 
  line-height: 2.8rem; 
  letter-spacing: 0.1em; 
  vertical-align: center;
}

@media (max-width: 768px) {
  .hero-desc { text-align: center; line-height: 2.3rem; letter-spacing: 0.09em; max-width: 575px; }
  }

  @media (max-width: 576px) {
    .hero-desc { text-align: center; line-height: 2rem; letter-spacing: 0.08em; max-width: 420px; }
    }

.hero-xone-badge {
  background: linear-gradient(45deg, indigo 30%, purple 80%);
  letter-spacing: 0.02em;
  transition: all 0.1s linear; }
.hero-xone-badge:hover {transform: scale(1.1);}

/* .hero-container { width:100%; height:100%; background: rgba(0,0,0,0.4); } */

.hero-header {
  width: 100%;
  color: white;
  text-align: center;
}

/* FOOTER */
#footer {
  position: relative;
  z-index: 7;
  background-color: #1D002F;
  background: linear-gradient(to bottom, rgba(29,0,47,0) 0%, rgba(29,0,47,0) 2%, rgba(29,0,47,100%) 83%);
}
#footer .footer-links { position: relative;  z-index: 11; }
#footer .footer-top { box-shadow: 0 11px 11px -11px rgba(155, 20, 128, 0.83); }

footer .mball-avatar {display: none;}

/* FOOTER :: NFT Hero Scrolling Reels */
.nft-hero {
  width: 100%;
  position: absolute;
  bottom: 0;
  /* z-index: 7; */
  border-bottom:1px solid rgba(11, 228, 228, 0.8);
  margin-bottom: 0;
  /* margin-top: -100px; */
/*   -webkit-box-shadow: 0 8px 6px -2px rgba(11, 228, 228, 0.75);
   -moz-box-shadow: 0 8px 6px -2px  rgba(11, 228, 228, 0.75);
        box-shadow: 0 8px 6px -2px rgba(11, 228, 228, 0.75); */
}

.nft-hero-text {
  text-align: center;
  position: absolute;
  z-index:3;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 650px;
  border-radius: 7px;
  padding: 15px 10px 30px 10px;
  margin: auto;
  background: rgba(29,0,47,0.75);
  -webkit-tap-highlight-color: transparent;
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 992px) {
.nft-hero-text{ width: 100%; border-radius:0; padding: 15px 10px 30px 10px; }
}

.nft-reel-1, .nft-reel-2, .nft-reel-3, .nft-reel-4, .nft-reel-5, .nft-reel-6, .nft-reel-7 {
/*   -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear; */
/*   animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; */
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  animation-direction: normal;
  -webkit-animation-direction: normal;
  animation-fill-mode: none;
  -webkit-animation-fill-mode: none;
  animation-play-state: running;
  -webkit-animation-play-state: running;
  background-color: rgba(0, 0, 0, 0);
  height: 71px;
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 7500px 71px !important;
  opacity: 0.75;
  position: relative;
  z-index:1;
  /* transform: translate3d(0,0,0); */
}
.nft-reel-1{
  background: url(../assets/mball-reel-7500x71-001.png) transparent repeat-x 0 0;
  -webkit-animation: slideright 700s infinite linear; /* Safari 4+ */
  -moz-animation: slideright 700s infinite linear; /* Fx 5+ */
  -o-animation: slideright 700s infinite linear; /* Opera 12+ */
  animation: slideright 700s infinite linear; /* IE 10+ */
}
.nft-reel-3{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-003.png?v=1646421666787) transparent repeat-x 0 0;
  -webkit-animation: slideright 777s infinite linear; /* Safari 4+ */
  -moz-animation: slideright 777s infinite linear; /* Fx 5+ */
  -o-animation: slideright 777s infinite linear; /* Opera 12+ */
  animation: slideright 777s infinite linear; /* IE 10+ */
}
.nft-reel-5{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-005.png?v=1646421677746) transparent repeat-x 0 0;
  -webkit-animation: slideright 900s infinite linear; /* Safari 4+ */
  -moz-animation: slideright 900s infinite linear; /* Fx 5+ */
  -o-animation: slideright 900s infinite linear; /* Opera 12+ */
  animation: slideright 900s infinite linear; /* IE 10+ */
}
.nft-reel-7{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-007.png?v=1646421692824) transparent repeat-x 0 0;
  -webkit-animation: slideright 950s infinite linear; /* Safari 4+ */
  -moz-animation: slideright 950s infinite linear; /* Fx 5+ */
  -o-animation: slideright 950s infinite linear; /* Opera 12+ */
  animation: slideright 950s infinite linear; /* IE 10+ */
}

.nft-reel-2{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-002.png?v=1646421660656) transparent repeat-x 0 0;
  -webkit-animation: slideleft 800s infinite linear; /* Safari 4+ */
  -moz-animation: slideleft 800s infinite linear; /* Fx 5+ */
  -o-animation: slideleft 800s infinite linear; /* Opera 12+ */
  animation: slideleft 800s infinite linear; /* IE 10+ */
}

.nft-reel-4{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-004.png?v=1646421672214) transparent repeat-x 0 0;
  -webkit-animation: slideleft 900s infinite linear; /* Safari 4+ */
  -moz-animation: slideleft 900s infinite linear; /* Fx 5+ */
  -o-animation: slideleft 900s infinite linear; /* Opera 12+ */
  animation: slideleft 900s infinite linear; /* IE 10+ */
}

.nft-reel-6{
  background: url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-reel-7500x71-006.png?v=1646421684898) transparent repeat-x 0 0;
  -webkit-animation: slideleft 900s infinite linear; /* Safari 4+ */
  -moz-animation: slideleft 900s infinite linear; /* Fx 5+ */
  -o-animation: slideleft 900s infinite linear; /* Opera 12+ */
  animation: slideleft 900s infinite linear; /* IE 10+ */
}


/* LOGO BALL */

.ball {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: relative;
    margin: auto;
    background: radial-gradient(circle at bottom, #81e8f6, #76deef 10%, #055194 80%, #062745 100%);
    box-shadow: 0px 0px 38px 0px #44b9fc,0px 0px 11px 1px #00c4cc;
  }
  .ball:hover{cursor: pointer;}
    .ball:before {
      content: "";
      position: absolute;
      display:none;
      top: 1%;
      left: 5%;
      width: 70%;
      height: 70%;
      border-radius: 100%;
      background: radial-gradient(circle at top, white, rgba(255, 255, 255, 0) 58%);
      -webkit-filter: blur(5px);
      filter: blur(5px);
      z-index: 2; }
    .ball:after {
      content: "";
      position: absolute;
      display: none;
      top: 5%;
      left: 10%;
      width: 80%;
      height: 80%;
      border-radius: 100%;
      -webkit-filter: blur(1px);
      filter: blur(1px);
      z-index: 2;
      -webkit-transform: rotateZ(-30deg);
      transform: rotateZ(-30deg); }
    .ball .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
      -webkit-transform: rotateX(90deg) translateZ(-160px);
      transform: rotateX(90deg) translateZ(-160px);
      z-index: 1; }
    .ball.plain {
      background: black; }
      .ball.plain:before, .ball.plain:after {
        display: none; }
    .ball.bubble {
      background-size: 100%;
      background-image: url("https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/mball-logo-200x200.svg?v=1650743685147"),radial-gradient(circle at 50% 55%, rgba(240, 245, 255, 0.9), rgba(240, 245, 255, 0.9) 40%, rgba(225, 238, 255, 0.8) 60%, rgba(43, 130, 255, 0.4));
      -webkit-animation: bubble-anim 2s ease-out infinite;
      animation: bubble-anim 2s ease-out infinite;
      transition: all 0.3s;
      cursor: pointer;
      opacity: 1;
      /* border: 2px solid #38daf0; */
    }
    .mbmenu-ball.ball.bubble {
      background-image: url("https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/ballmenu-icon.png?v=1643823631145"),radial-gradient(circle at 50% 55%, rgba(240, 245, 255, 0.9), rgba(240, 245, 255, 0.9) 40%, rgba(225, 238, 255, 0.8) 60%, rgba(113, 17, 161, 0.4));
    }
      .ball.bubble:hover,.ball.bubble:active {opacity: 1;}
      .ball.bubble:before {
        -webkit-filter: blur(0);
        filter: blur(0);
        height: 80%;
        width: 40%;
        background: radial-gradient(circle at 130% 130%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 58%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
        -webkit-transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg);
        transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg); }
      .ball.bubble:after {
        display: block;
        background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, white 80%, white 84%, rgba(255, 255, 255, 0) 100%); }
  
  .stage {
    width: 110px;
    height: 110px;
    margin: 0 auto; 
    overflow: visible;
    position:relative;
    border-radius:50%;
    border: none;
    background-color: transparent;
    -webkit-perspective: 888px;
    -moz-perspective: 888px;
    -ms-perspective: 888px;
    -o-perspective: 888px;
    perspective: 888px;
    -webkit-perspective-origin: 50% 50%;
    -moz-perspective-origin: 50% 50%;
    -ms-perspective-origin: 50% 50%;
    -o-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
  }
  @media only screen and (max-width: 992px) {
    .stage.ball-topleft {margin: 0 0 0 28px !important;}
  }
  

  /* LOGO 3D TEXT */
  .logo-3d-text {
    padding:0;
    vertical-align:middle;
    font-size: 22px;
    letter-spacing: .05rem;
    line-height:22px;
    color: #1fc7dd;
     font-family: 'Dosis', Gadget, sans-serif;
     transition: all 0.2s;
   }
  .logo-3d-text:hover, .logo-3d-text:active {
    color: rgb(11, 218, 227);
    letter-spacing: .06rem;
    transform: translateX(5px) translateY(0px) scale(1);
  }
  .logo-3d-text:active {
      text-shadow: 0px 0px 0 rgb(-17,180,187),
                   0px 1px 0 rgb(-33,164,171),
                   0px 2px 0 rgb(-50,147,154),
                   0px 3px  0 rgb(-67,130,137),
                   0px 4px 3px rgba(0,0,0,0.6),
                   0px 4px 1px rgba(0,0,0,0.5),
                   0px 0px 3px rgba(0,0,0,.2);
  }
  
/* effect-shine */
.effect-shine {text-decoration: none;}
.effect-shine:hover {
  mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000000 50%, rgba(0,0,0,.6) 70%);
  -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000000 50%, rgba(0,0,0,.6) 70%);
  mask-size: 200%;
  -webkit-mask-size: 200%;
  animation: shine 2s infinite;
}

@-webkit-keyframes shine {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}

@keyframes shine {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}

/* NFT IMG - auto-scrolling image containers */
/* https://stackoverflow.com/questions/43744887/fullscreen-infinite-scrolling-background */

@media screen and (max-aspect-ratio: 4/3) {
  .nft-img-inner {
      height: 100%;
      width: auto !important;
  }
  .nft-img {
     height: 100%;
     width: auto !important;
  }
}

.nft-img-container {
border: solid 1px black;
display: inline-block;
overflow: hidden;
}

#nft-ctn1 {
width: 300px;
height: 150px;
}

#nft-ctn2 {
width: 200px;
height: 350px;
}

.nft-img-inner {
width: 100%;
position: relative;
animation: scroll 5s infinite linear;
display: inline-block;
}


.nft-img-inner:after {
content: "";
height: 500%;
width: 100%;
left: 0px;
position: absolute;
background-image: url(https://i.stack.imgur.com/FlK9o.jpg);
background-size: 100% 20%;
}

.nft-img { width: 100%; }

/* Footer Mint Btn */
.btn-mint{
  transition: all 0.3s;
  padding: 15px 40px;
  letter-spacing: 0.05rem;
  margin: auto;
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
}
.btn-mint:hover, .btn-mint:active {
 letter-spacing: .1rem;
 transform: translateX(0px) translateY(0px) scale(1);
}

/* ATROPOS my-atropos */

/* .container.atropos-wrap {
  padding: 0px 40px;
  max-width: 600px;
  margin: 32px auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.atropos-banner {
  width: 100%;
}
.atropos-banner .atropos-inner {
  border-radius: 10px;
}
.atropos-banner img {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  object-fit: contain;
  display: block;
  z-index: 1;
  transform-style: preserve-3d;
  pointer-events: none;
}
.atropos-banner img.atropos-banner-spacer {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  visibility: hidden;
}
.atropos-banner .atropos-shadow {
  filter: blur(50px);
  opacity: 0.5;
}
.atropos-banner .atropos-highlight {
  z-index: 100;
}

.atropos-banner-text {
  position: absolute;
  color: #fff;
  font-weight: bold;
  left: 0%;
  top: 0%;
}
.atropos-active .atropos-banner-text {
} */

/* figma slider */
.mslide-1 {
  display: flex;background-image: linear-gradient( 135deg, #97ABFF 10%, #123597 100%);
  background: linear-gradient(135deg, #2fbbe6 10%, #123597 100%);
  color: rgb(0, 0, 0);
  border-top: 1px solid white;
  overflow: hidden;
  padding: 10px;
}

.metaballix-sl1d3r {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  -webkit-box-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  white-space: nowrap;
  user-select: none;
  animation: 30s linear 0s infinite normal none running figslide-1;
}

.metaballix-sl1d3 {
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  margin: 0px 20px;
}
