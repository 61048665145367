/* ########################## */
/* Animations Load From Here */

/* 3-DOT Loader (from original dialog) */
.loading:after {
	content: " .";
	animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
	0%,
	20% {
	  color: rgba(0, 0, 0, 0);
	  text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	40% {
	  color: white;
	  text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	60% {
	  text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	80%,
	100% {
	  text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
	}
  }
  
@keyframes scroll {
  from {transform: translateY(-100%);}
    to {transform: translateY(-200%);}
  }

@-webkit-keyframes bubble-anim {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1); }
  
    20% {
      -webkit-transform: scaleY(0.95) scaleX(1.05);
      transform: scaleY(0.95) scaleX(1.05); }
  
    48% {
      -webkit-transform: scaleY(1.1) scaleX(0.9);
      transform: scaleY(1.1) scaleX(0.9); }
  
    68% {
      -webkit-transform: scaleY(0.98) scaleX(1.02);
      transform: scaleY(0.98) scaleX(1.02); }
  
    80% {
      -webkit-transform: scaleY(1.02) scaleX(0.98);
      transform: scaleY(1.02) scaleX(0.98); }
  
    97%, 100% {
      -webkit-transform: scale(1);
      transform: scale(1); } }
  
  @keyframes bubble-anim {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1); }
  
    20% {
      -webkit-transform: scaleY(0.95) scaleX(1.05);
      transform: scaleY(0.95) scaleX(1.05); }
  
    48% {
      -webkit-transform: scaleY(1.1) scaleX(0.9);
      transform: scaleY(1.1) scaleX(0.9); }
  
    68% {
      -webkit-transform: scaleY(0.98) scaleX(1.02);
      transform: scaleY(0.98) scaleX(1.02); }
  
    80% {
      -webkit-transform: scaleY(1.02) scaleX(0.98);
      transform: scaleY(1.02) scaleX(0.98); }
  
    97%, 100% {
      -webkit-transform: scale(1);
      transform: scale(1); } }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* MINTBOX GRADIENT ANIM */
@-webkit-keyframes mintbox-gradient {
  0%{background-position:0% 73%}
  50%{background-position:100% 28%}
  100%{background-position:0% 73%}
}
@-moz-keyframes mintbox-gradient {
  0%{background-position:0% 73%}
  50%{background-position:100% 28%}
  100%{background-position:0% 73%}
}
@keyframes mintbox-gradient {
  0%{background-position:0% 73%}
  50%{background-position:100% 28%}
  100%{background-position:0% 73%}
}

@keyframes slideright {
  from { background-position: 0 0; }
  to { background-position: -500% 0; }
}

@keyframes slideleft {
  from { background-position: 0 0; }
  to { background-position: 600% 0; }
}  

/* FIGMA M-SLIDER Anim */
@keyframes figslide-1 {
  from{transform: translateX(0px);}
  to{transform: translateX(-100%);}
}
/* NFT Reel (mbp-photos > img-box) */
@keyframes nftslide-1 {
  from{transform: translateX(0px);}
  to{transform: translateX(-5%);}
}

@keyframes floating {
0% {
    transform: translate(50px,0);
}
65% {
    transform: translate(0,0);
}
100% {
    transform: translate(50px,0);
}
}

@keyframes animate-shadow {
    0% {
      filter: drop-shadow(0 0 0.38rem rgb(202, 51, 182)); 
    }
    50% {
      filter: drop-shadow(0 0 0.38rem rgb(151, 51, 240)); 
    }
    100% {
      filter: drop-shadow(0 0 0.38rem rgb(202, 51, 182)); 
    }
  }

@keyframes animate-shadow-ocean {
  0% {
    filter: drop-shadow(0 0 0.5rem rgba(22, 222, 222, 0.65)); 
  }
  50% {
      filter: drop-shadow(0 0 0.5rem rgba(252, 101, 252, 0.75)); 
  }
  100% {
    filter: drop-shadow(0 0 0.5rem rgba(22, 222, 222, 0.65)); 
  }
}

@keyframes animate-shadow-white {
  0% {
    filter: drop-shadow(1px 5px 1.88rem rgba(172, 255, 255, 1)); 
  }
  50% {
      filter: drop-shadow(1px 5px 1.88rem rgba(255, 255, 255, 1)); 
  }
  100% {
    filter: drop-shadow(1px 5px 1.88rem rgba(172, 255, 255, 1)); 
  }
}

@keyframes gradient-txt-change {
  0% { color: red; }
  50% { color: blue; }
  100% { color: red; }
}

@keyframes purple-pulse {
  0% {}
  50% { opacity: 0.65; }
  100% {}
}

@keyframes highlite1 {
  /* 15% { filter: drop-shadow(0 0 0 rgb(29,0,47)); } */
  20% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(38, 137, 182); }
  25% { filter: drop-shadow(1px 1px 1rem rgb(47, 0, 47)); color: rgb(255,255,255); }
  30% { filter: drop-shadow(1px 1px 1rem rgb(47, 0, 47)); color: rgb(255,255,255); }
  35% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(38, 137, 182); }
  40% { filter: drop-shadow(0 0 0 rgb(29,0,47)); }
  100% { }
}
@keyframes highlite2 {
  /* 40% { filter: drop-shadow(0 0 0 rgb(29,0,47)); } */
  45% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(101, 38, 182); }
  50% { filter: drop-shadow(1px 1px 1rem rgb(47,0,47)); color: rgb(255,255,255); }
  55% { filter: drop-shadow(1px 1px 1rem rgb(47,0,47)); color: rgb(255,255,255); }
  60% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(101, 38, 182); }
  65% { filter: drop-shadow(0 0 0 rgb(29,0,47)); }
  100% { }
}
@keyframes highlite3 {
  /* 65% { filter: drop-shadow(0 0 0 rgb(29,0,47)); } */
  70% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(182, 38, 182); }
  75% { filter: drop-shadow(1px 1px 1rem rgb(47,0,47)); color: rgb(255,255,255); }
  80% { filter: drop-shadow(1px 1px 1rem rgb(47,0,47)); color: rgb(255,255,255); }
  85% { filter: drop-shadow(1px 5px 1.5rem rgb(29,0,47)); color: rgb(182, 38, 182); }
  90% { filter: drop-shadow(0 0 0 rgb(29,0,47)); }
  100% { }
}

/* ANIMATABLE -- Lazy Loading Classes with Reverse */
/* codepen.io/bramus/pen/AzmevE */

.animatable {
  
  /* initially hide animatable objects */
  visibility: hidden;
  
  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;   
  -moz-animation-play-state: paused;     
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;   
  animation-play-state: paused; 
}

/* show objects being animated */
.animate-in {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 0.88s;
  -moz-animation-duration: 0.88s;
  -ms-animation-duration: 0.88s;
  -o-animation-duration: 0.88s;
  animation-duration: 0.88s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

.animate-out {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;

  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  -ms-animation-direction: reverse;
  -o-animation-direction: reverse;
  animation-direction: reverse;
}

.animate-out:after {
  content: "";
  letter-spacing: inherit;
}

/* CSS Animations extracted from glifo.uiparade.com */
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}



@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	60% {
		-webkit-transform: translateX(20px);
	}

	80% {
		-webkit-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}

	60% {
		-moz-transform: translateX(20px);
	}

	80% {
		-moz-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}

	60% {
		opacity: 1;
		-o-transform: translateX(20px);
	}

	80% {
		-o-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInLeft {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}

	60% {
		transform: translateX(20px);
	}

	80% {
		transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}

	60% {
		-webkit-transform: translateX(-20px);
	}

	80% {
		-webkit-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}

	60% {
		-moz-transform: translateX(-20px);
	}

	80% {
		-moz-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}

	60% {
		-o-transform: translateX(-20px);
	}

	80% {
		-o-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInRight {
	0% {
		opacity: 0;
		transform: translateX(2000px);
	}

	60% {
		transform: translateX(-20px);
	}

	80% {
		transform: translateX(5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
	}
	50% {
		-webkit-transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes bounceIn {
	0% {
		opacity: 0;
		-moz-transform: scale(.3);
	}

	50% {
		-moz-transform: scale(1.05);
	}

	70% {
		-moz-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@-o-keyframes bounceIn {
	0% {
		opacity: 0;
		-o-transform: scale(.3);
	}

	50% {
		-o-transform: scale(1.05);
	}

	70% {
		-o-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-o-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(.3);
	}

	50% {
		transform: scale(1.05);
	}

	70% {
		transform: scale(.9);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-webkit-keyframes moveUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(40px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes moveUp {
	0% {
		opacity: 1;
		-moz-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes moveUp {
	0% {
		opacity: 1;
		-o-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		opacity: 1;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@-o-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}

.animate-in.animationDelay, .animate-out.animationDelay {
	animation-delay:.4s;
	-webkit-animation-delay:.4s;
}
.animate-in.animationDelayMed, .animate-out.animationDelayMed {
	animation-delay:1.2s;
	-webkit-animation-delay:1.2s;
}
.animate-in.animationDelayLong, .animate-out.animationDelayLong {
	animation-delay:1.6s;
	-webkit-animation-delay:1.6s;
}
.animate-in.fadeBgColor, .animate-out.fadeBgColor {
	-webkit-animation-name: fadeBgColor;
	-moz-animation-name: fadeBgColor;
	-o-animation-name: fadeBgColor;
	animation-name: fadeBgColor;
}
.animate-in.bounceIn, .animate-out.bounceIn {
	-webkit-animation-name: bounceIn;
	-moz-animation-name: bounceIn;
	-o-animation-name: bounceIn;
	animation-name: bounceIn;
}
.animate-in.bounceInRight, .animate-out.bounceInRight {
	-webkit-animation-name: bounceInRight;
	-moz-animation-name: bounceInRight;
	-o-animation-name: bounceInRight;
	animation-name: bounceInRight;
}
.animate-in.bounceInLeft, .animate-out.bounceInLeft {
	-webkit-animation-name: bounceInLeft;
	-moz-animation-name: bounceInLeft;
	-o-animation-name: bounceInLeft;
	animation-name: bounceInLeft;
}
.animate-in.fadeIn, .animate-out.fadeIn {
	-webkit-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	animation-name: fadeIn;
}
.animate-in.fadeInDown, .animate-out.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
.animate-in.fadeInUp, .animate-out.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.animate-in.moveUp, .animate-out.moveUp {
	-webkit-animation-name: moveUp;
	-moz-animation-name: moveUp;
	-o-animation-name: moveUp;
	animation-name: moveUp;
}
