:root {
  
  /* Main Settings  */
  --main-background-color: rgb(29,0,47);

  --text-primary-color: rgb(255,255,255);
  --text-secondary-color: #380056;

  /* Nav Settings  */
  --nav-link-color: rgb(236, 236, 236);
  --nav-link-hover-color: rgb(11, 218, 227);
  --nav-link-hover-background-color: rgba(3, 24, 80, 0.4);
  --nav-background-color: none;
  --nav-height: 100px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */
  --mobile-nav-background-color: #380056;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(11, 218, 227);
  --mobile-nav-link-hover-color: rgb(0, 196, 204);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: linear-gradient(-11deg, rgba(255,255,255,0.3), rgba(255,255,255,0.65), rgba(255,255,255,0.83), rgba(255,255,255,0.92));
  --card-border-radius: 8px;
  --card-shadow: none; /* Default: 1px 1px 20px -13px rgba(255, 255, 255, 0.8) */

  /* Minting Area */
  --minting-box-color: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%);
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(75, 78, 99);
  --minting-box-text-color: rgba(222,222,222);


  /* Minting Button */
  --minting-button-color: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
  --minting-button-text-color: white;

}






