/* ####### APP MAIN STYLESHEET ####### */
/* METABALL MINTER --- METABALL.WORLD */

/* body::before {
  content: "METABALLIX";
  color:rgb(65, 65, 65);
  font-size: 8rem;
  font-weight: 300;
  font-style: normal;
} */

@media only screen and (max-width: 992px) {
  body, html { width: 100%; overflow-x: hidden; }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App { text-align: center; }
* { margin: 0; padding: 0; }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  position: relative; 
  background-color: #380056 !important;
  /* background: linear-gradient(45deg, rgb(11, 146, 92), rgb(14, 101, 133), rgb(64, 15, 129), rgb(160, 39, 124), rgb(182, 7, 95));
  background-size: 200% 200%;
  animation: gradient 10s ease infinite; */
  margin: 0;
  font-family: "Dosis", "Dongle", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
   "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif;
  --bs-font-sans-serif: "Dosis", "Dongle",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",
  Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behaviour: smooth;
  font-size: 1.2rem !important;
  color: var(--text-primary-color) !important;
  letter-spacing: 0.01em;
}

/* body:after {
  position: fixed;
  top: 0;
  left: 0;
  background: url(https://cdn.glitch.global/84180755-58e4-4c80-b543-77cacf869330/xone-bg-loop.gif?v=1648567442504) no-repeat, radial-gradient(ellipse farthest-corner at 50% 70%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7));
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  background-blend-mode: screen;
  background-position: top;
  background-size: cover;
  content: "";
  height: 100%;
  max-height: 100vh;
  width: 100%;
  opacity: 1;
  z-index: -1;
} */

/* #home { 
  backdrop-filter: blur(2px); 
} */

header {
  /* background: linear-gradient(-180deg,rgba(29,0,55,.55) 0%, rgba(29,0,55,0) 38%, rgba(29,0,55,0) 74%, rgba(29,0,55,0.75) 100%); */
  width: 100%;
  margin: auto;
  align-items: center;
  display: grid;
  gap: 20px;
  /* padding: 35px; */
  flex-direction: column;
}

nav {
  background: transparent;
/*   background: linear-gradient(to right, #380c6e 0%, #740560 100%), linear-gradient(-180deg,rgba(29,0,29,0.7) 0%, rgba(29,0,38,0.22) 29%, rgba(29,0,47,0.11) 100%);
  -webkit-box-shadow: 0px 2px 1.1rem 1px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 2px 1.1rem 1px rgba(0, 0, 0, 0.65); */
}


.mintHeader {
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 992px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    gap: 20px;
  }
}
/* 
nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
}
 */
nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  border-radius: 4px;
  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}
/* 
.mobile-menu-button {
  font-size: 1.65rem;
  color: rgba(255, 255, 255,0.5);
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: color 0.4s ease-in-out;
  z-index: 45;
}

.mobile-menu-button:hover {
color: rgba(255, 255, 255,0.9);
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: var(--mobile-nav-background-color, rgb(113, 17, 161));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}
 */
.text-muted {
  color: darkgrey;
}

.social-icons .nav-social {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
    top: 30px;
  }

  .nav-logo {
    height: 50px !important;
  }
/* to bring basketball avatar up on mobile devices :: 
  nav {
    height: 100px;
    margin-bottom: -47px;
  }
*/
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 1100px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq2 {
  max-width: 1100px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq2 div {
  margin-bottom: 20px;
}

.faq2 p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card.contentcard {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 35px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
  border: none;
}

.container {
  position: relative;
  /* margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto; */
  max-width: 1100px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1100px;
  margin: auto;
}

/* METABALL MINTER v1.1 (cmv2) - CSS Styles */

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }
