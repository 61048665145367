/* ############################################################# */
/* Bootstrap Timeline - https://codepen.io/jasondavis/pen/nXLJbg */


/* Bootstrap Timeline 2 */
/* https://bootsnipp.com/snippets/O5q8E */

.main-timeline2:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline2 .timeline2{
    width: 50%;
    margin: 0 0 10px 5px;
    float: right;
}
.main-timeline2 a { text-decoration: none; }
.main-timeline2 .timeline2-content{
    color: #666;
    background: linear-gradient(to right,#f1f1f1,rgba(255,255,255,0.2));
    min-height: 120px;
    padding: 15px;
    border-radius: 10px;
    display: block; 
}
.main-timeline2 .timeline2-content:hover{ text-decoration: none; }
.timeline2-year{
    color: #00C4CC;
    font-size: 2.2rem;
    line-height: 2.2rem;
    font-weight: 600;
    font-style: none;
    padding-bottom: 5px;
}
.main-timeline2 .title{
    color: #fff;
    background: linear-gradient(to right,#FC424A,#DC8C3E);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 7px 15px;
    margin: 0 0 5px;
    border-radius: 4px;
    display: inline-block;
}
.main-timeline2 .description{
    /* color:rgba(0, 0, 0, 0.92); */
    line-height: 1.5rem;
    padding: 5px 5px 10px 5px;
    margin: 0;
}
.main-timeline2 .timeline2:nth-child(even){
    margin: 0 5px 10px 0;
    float: left;
}
.main-timeline2 .timeline2:nth-child(even) .timeline2-content{
    background: linear-gradient(to left,#f1f1f1,rgba(255,255,255,0.2));
    text-align: right;
}
.main-timeline2 .timeline2:nth-child(even) .description{ text-align: left; }
.main-timeline2 .timeline2:nth-child(4n+2) .timeline2-year{ color: #3BCD59; }
.main-timeline2 .timeline2:nth-child(4n+2) .title{ background: linear-gradient(to right,#3BCD59,#8BD33B); }
.main-timeline2 .timeline2:nth-child(4n+3) .timeline2-year{ color: #10A5D7; }
.main-timeline2 .timeline2:nth-child(4n+3) .title{ background: linear-gradient(to right,#10A5D7,#69D4D7); }
.main-timeline2 .timeline2:nth-child(4n+4) .timeline2-year{ color: #E31F9A; }
.main-timeline2 .timeline2:nth-child(4n+4) .title{ background: linear-gradient(to right,#E31F9A,#CF21C2); }
.main-timeline2 .timeline2:nth-child(4n+5) .timeline2-year{ color: #7111A1; }
.main-timeline2 .timeline2:nth-child(4n+5) .title{ background: linear-gradient(to right,#7111A1,#7C2AE8); }
@media screen and (max-width: 992px){
    .main-timeline2 .timeline2{ width: 100%; } 
}
@media screen and (max-width:576px){
    .main-timeline2 .timeline2-content,
    .main-timeline2 .timeline2:nth-child(even) .timeline2-content{
        background: linear-gradient(#f1f1f1,transparent);
        text-align: left;
    }
    .main-timeline2 .timeline2:nth-child(even) .description{ 
        text-align: left;
    }
}


/* FAQ section bs 4.1 - codepen.io/moso/pen/vKGxMx */

/* FAQ Section - Bootstrap 5 Accordion */
/* .card.contentcard.faqcard { background: rgba(146, 74, 173, 0.65); } */

.card.faqcard {
  background: transparent;
  width: 100%;
  padding: 1.5rem;
  border-width: 1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.context-container {
  background-color: transparent;
}

#faq .accordion-item {
    margin-bottom: 11px;
}
#faq .accordion-button {
    color: #FFFFFF;
    background-color: transparent;
    background: -webkit-linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%);
    background: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
    font-size: 1.3rem;
    letter-spacing: 0.03em;
    text-align: left;
}
#faq .accordion-collapse { border: none; }
#faq .accordion-body {
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  /* color: #212529;  */
}

.faqcard .nav .nav-link {
  background: linear-gradient(35deg, rgba(29,0,47, 0.3) 20%, rgba(29,0,47,0.8) 80%);
  border: 1px solid #00c4cc;
  border-bottom: 4px solid #00c4cc;
  color: #ffffff;
  transition: all 0.1s;
  letter-spacing: 0.03em;
}
.faqcard .nav .nav-link.active {
  background: -webkit-linear-gradient(left, #22c777 0%, #00b6ce 100%);
  background: -o-linear-gradient(left, #22c777 0%, #00b6ce 100%);
  background: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
}

.faqcard .nav .nav-link:hover {
  /* background: linear-gradient(35deg, rgba(29,0,47, 0.2) 20%, rgba(29,0,47,0.5) 80%); */
  /* color: #00c4cc; */
  border-bottom-width: 7px;
}

/* SERVICE SECTIONS - snippets.wrappixel.com */
.service-btn {
    background: #22c777;
    background: -webkit-linear-gradient(legacy-direction(to right), #22c777 0%, #00b6ce 100%);
    background: -webkit-gradient(linear, left top, right top, from(#22c777), to(#00b6ce));
    background: -webkit-linear-gradient(left, #22c777 0%, #00b6ce 100%);
    background: -o-linear-gradient(left, #22c777 0%, #00b6ce 100%);
    background: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
    border: 0px;
    color: rgba(255,255,255,0.92);
    transition: all 0.2s ease-in-out;
  }

.service-btn.btn-dark {
    background: #18002e;
    border: 1px solid #11888a;
}
.service-btn.btn-dark:hover {
    background: #22083a;
    border-color:#00b6ce;
}

.service-btn:hover {
    background: #00b6ce;
    background: -webkit-linear-gradient(legacy-direction(to right), #00b6ce 0%, #22c777 100%);
    background: -webkit-gradient(linear, left top, right top, from(#00b6ce), to(#22c777));
    background: -webkit-linear-gradient(left, #00b6ce 0%, #22c777 100%);
    background: -o-linear-gradient(left, #00b6ce 0%, #22c777 100%);
    background: linear-gradient(to right, #00b6ce 0%, #22c777 100%);
    color: white;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  .service-btn.btn-md {
    padding: 15px 45px;
    font-size: 1.1rem;
  }

/* XONE.LAND SECTION :: Service1 3x1 */
/* 
.service-1 {
  color: #ffffff;
  font-weight: 300;
}
.service-1 .subtitle {
  color: rgba(255,255,255,0.65);
  line-height: 24px;
} 
.service-1 h1,
.service-1 h2,
.service-1 h3,
.service-1 h4,
.service-1 h5,
.service-1 h6 {
  color: #ffffff;
}
*/

.service-1 h6 { font-size: 1.3rem; }

.service-1 .bg-light {
  background-color: #f4f8fa !important;
}

  .service-1 .card.card-shadow {
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  }
  
  .service-1 .wrap-service1-box .card-body {
    padding: 40px;
  }

/* Service6 4x1 - */
.service-6 h1, .service-6 h2, .service-6 h3, .service-6 h4, .service-6 h5, .service-6 h6 {
    color: #3e4555;
  }
  
  .service-6 .font-weight-medium {
      font-weight: 500;
  }
  
  .service-6 .subtitle {
      color: #8d97ad;
      line-height: 24px;
  }
  
  .service-6 a {
      text-decoration: none;	
  }
  
  .service-6 .wrap-service6-box {
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  .service-6 .wrap-service6-box .card-body {
    padding: 1em;
  }
  
  .service-6 .wrap-service6-box .card-body .linking {
    color: #ffffff;
  }
  
  .service-6 .wrap-service6-box .card-body .linking:hover {
    color: #263238;
  }
  
  .service-6 .wrap-service6-box .card-body p {
    opacity: 0.8;
  }
  
  .service-6 .wrap-service6-box:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }

.bg-dark-gradient {
    background-color: #2b0146;
    background: linear-gradient(to top right, #1d002f 0%, #2b0146 90%);
    background: -webkit-linear-gradient(to top right, #1d002f 0%, #2b0146 90%);
    background: -o-linear-gradient(to top right, #1d002f 0%, #2b0146 90%);
}
.bg-yellow-gradient {
    background-color: #ffbd07;
    background: linear-gradient(to top right, #fda137 0%, #ffce3c 90%);
    background: -webkit-linear-gradient(to top right, #fda137 0%, #ffce3c 90%);
    background: -o-linear-gradient(to top right, #fda137 0%, #ffce3c 90%);
}

.bg-green-gradient {
    background-color: #22c777;
    background: linear-gradient(to top right, #22c777 0%, #00b6ce 90%);
    background: -webkit-linear-gradient(to top right, #22c777 0%, #00b6ce 90%);
    background: -o-linear-gradient(to top right, #22c777 0%, #00b6ce 90%);
}

.bg-blue-gradient {
      background-color: #188ef4;
      background: linear-gradient(to top right, #188ef4 0%, #782ccf 90%);
      background: -webkit-linear-gradient(to top right, #188ef4 0%, #782ccf 90%);
      background: -o-linear-gradient(to top right, #188ef4 0%, #782ccf 90%);
  }

.bg-purple-gradient {
    background-color: #662D91;
    background: linear-gradient(to top right, #662D91 0%, #b80b70 90%);
    background: -webkit-linear-gradient(to top right, #662D91 0%, #b80b70 90%);
    background: -o-linear-gradient(to top right, #662D91 0%, #b80b70 90%);
}

.bg-pink-gradient {
    background-color: #be0069;
    background: linear-gradient(to top right, #be0069 0%, #ff6a5b 100%);
    background: -webkit-linear-gradient(to top right, #be0069 0%, #ff6a5b 100%);
    background: -o-linear-gradient(to top right, #be0069 0%, #ff6a5b 100%);
}

.bg-red-gradient {
      background-color: #ff4d7e;
      background: linear-gradient(to top right, #ff4d7e 0%, #ff6a5b 100%);
      background: -webkit-linear-gradient(to top right, #ff4d7e 0%, #ff6a5b 100%);
      background: -o-linear-gradient(to top right, #ff4d7e 0%, #ff6a5b 100%);
}

.service-6 .btn-outline-success {
          color: #22c777 !important;
      background-color: transparent;
      border-color: #22c777 !important;
  }
  
  /* .service-6 .btn-outline-success:hover {
        background: #2cdd9b;
      border-color: #2cdd9b;
      color: #ffffff !important;
  }
  
  .service-6 .btn-md {
      padding: 15px 45px;
      font-size: 16px;
  }
   */

/* FEATURE CARDS (XONE CARDS) 3x1 */

#feature-cards .feat-1, #feature-cards .feat-2, #feature-cards .feat-3 {
    margin: auto;
    position: relative;
    width: 30%;
    padding-bottom : 30%; /* = width for a 1:1 aspect ratio */
    background-position: center center !important;
    background-repeat:no-repeat !important;
    background-size: contain !important;
    background-attachment: fixed;
}

#feature-cards .feat-1 {
    background: transparent url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-card-sq-2.png?v=1647310586602) no-repeat;
}
#feature-cards .feat-2 {
    background: transparent url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-card-sq-1.png?v=1647310590488) no-repeat;
}
#feature-cards .feat-3 {
    background: transparent url(https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-card-sq-3.png?v=1647310594733) no-repeat;
}

/* NFT SQUARES GRID 8x2 - jsfiddle.net/webtiki/MpXYr/2/ */
#nft-display .container-fluid {
    overflow: hidden;
    text-align: center;
    align-items: center;
    vertical-align: bottom;
    margin: auto 0 auto 5px;
    padding: 0 3%;
}

.nft-square {
    float:left;
    position: relative;
    width: 11%;
    padding-bottom : 11%; /* = width for a 1:1 aspect ratio */
    border-radius: 3px;
    margin:1.5%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover; /* you change this to "contain" if you don't want the images to be cropped */
    transition: all 0.5s ease-in-out;
}
/* .nft-square .nft-mask { width: 100%; height: 100%; background: none; transition: all 0.5s ease-in-out; }
.nft-square .nft-mask:hover { background: linear-gradient(45deg,rgba(29, 236, 197, 0.5),rgba(91, 14, 214, 0.5) 100%); } */

.nft-square.img_1-1{background-image:url('https://farm4.staticflickr.com/3766/12953056854_b8cdf14f21.jpg');}
.nft-square.img_1-2{background-image:url('https://farm7.staticflickr.com/6092/6227418584_d5883b0948.jpg');}
.nft-square.img_1-3{background-image:url('https://farm8.staticflickr.com/7187/6895047173_d4b1a0d798.jpg');}
.nft-square.img_1-4{background-image:url('https://farm8.staticflickr.com/7163/6822904141_50277565c3.jpg');}
.nft-square.img_1-5{background-image:url('https://farm7.staticflickr.com/6139/5986939269_10721b8017.jpg');}
.nft-square.img_1-6{background-image:url('https://farm4.staticflickr.com/3766/12953056854_b8cdf14f21.jpg');}
.nft-square.img_1-7{background-image:url('https://farm7.staticflickr.com/6092/6227418584_d5883b0948.jpg');}
.nft-square.img_1-8{background-image:url('https://farm8.staticflickr.com/7187/6895047173_d4b1a0d798.jpg');}

.nft-square.img_2-1{background-image:url('https://farm4.staticflickr.com/3766/12953056854_b8cdf14f21.jpg');}
.nft-square.img_2-2{background-image:url('https://farm7.staticflickr.com/6092/6227418584_d5883b0948.jpg');}
.nft-square.img_2-3{background-image:url('https://farm8.staticflickr.com/7187/6895047173_d4b1a0d798.jpg');}
.nft-square.img_2-4{background-image:url('https://farm8.staticflickr.com/7163/6822904141_50277565c3.jpg');}
.nft-square.img_2-5{background-image:url('https://farm7.staticflickr.com/6139/5986939269_10721b8017.jpg');}
.nft-square.img_2-6{background-image:url('https://farm4.staticflickr.com/3766/12953056854_b8cdf14f21.jpg');}
.nft-square.img_2-7{background-image:url('https://farm7.staticflickr.com/6139/5986939269_10721b8017.jpg');}
.nft-square.img_2-8{background-image:url('https://farm7.staticflickr.com/6092/6227418584_d5883b0948.jpg');}


/* ChatLoop Slider */

/* iPhone Mockup - codepen.io/andreaswik/pen/bgZdGW */

.iphone {
    display: inline-block;
    margin: 22px 11px;
    background-color: #f8f8f8;
    border: 1px solid #c0c0c0;
    padding: 0 8px 0 8px;
    border-radius: 22px;
    max-width: 100% !important;
    box-shadow: 0 0 38px -5px rgba(155, 20, 128, 0.9), inset 0 0 0.47em rgb(29 0 38 / 74%);
    position: relative;
    z-index: 3;
  }
  
  .iphone-screenshot {
    min-width: 200px;
    width: 100%;
    border: 1px inset rgba(38, 173, 155, 0.8);
    border-radius: 3px;
  }
  
  .iphone-small-round-top {
    margin: 11px auto;
    width: 5px;
    height: 5px;
    background-color: #c0c0c0;
    border-radius: 50%;
  }
  
  .iphone-round-top-left {
    float: left;
    margin-left: 65px;
    margin-top: -2px;
    width: 9px;
    height: 9px;
    background-color: #c0c0c0;
    border-radius: 50%;
  }
  
  .iphone-speaker {
    margin: 15px auto;
    margin-top: 10px;
    width: 30px;
    height: 5px;
    background-color: #c0c0c0;
    border-radius: 3px;
  }
  
  .iphone-button {
    border-radius: 50%;
    margin: 10px auto;
    width: 30px;
    height: 30px;
    border: 2px solid #c0c0c0;
    background: none !important;
  }
  
  .iphone-blue {
    background-color: #71abee;
    border-color: #447ec1;
  }
  
  .iphone-blue div {
    background-color: #447ec1;
    border-color: #447ec1;
  }
  
  .iphone-pink {
    background-color: #eec9ca;
    border-color: #d29fa0;
  }
  
  .iphone-pink div {
    background-color: #d29fa0;
    border-color: #d29fa0;
  }

  
/* MBP Profile Card - METABALLS */

/* 
.header{ font-size:35px; }
.sub{ font-size:20px; }
.description{ font-size:1.3rem; }
.number{ font-size:1.8rem; margin-bottom:0; }
*/

.mbp-slider-card{
  position: relative;
  z-index: 3;
  text-align:center;
  justify-content:center;
  margin:50px auto 0 auto;
  background-color: #200233;
  border-radius:0;
}

.mbp-content{
  text-align:center;
  padding:0 30px;
}

.box{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-around;
}

.box.media, .box.followers, .box.following { flex-direction: column; }

.mbp-logo {
  padding: 0;
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto; 
  margin-top: -65px;
  overflow: hidden;
  border: 10px #6e0c5e solid;
  box-shadow: 0 0 1rem #92119d;
  filter: drop-shadow(0 0 1.1rem #92119d);
  background-color: #1d002f;
  background: linear-gradient(45deg, #380c6e 0%, #6e0c5e 100%);
}
/* .mbp-logo img { object-fit: fill; } */

@media screen and (max-width: 576px) {
  .mbp-logo { width: 150px; height: 150px; }
  }

.mbp-logo img {
  width: 111%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
}
.mbp-photos {
  overflow-x: scroll;
}
.mbp-photos-inner {
  display:flex;
  overflow: visible;
  padding-top: 11px;
  padding-bottom: 11px;
  transform-style: preserve-3d;
  width: 100%;
  animation: 3s linear infinite alternate none running nftslide-1;
}

.mbp-nft-img{
  width:100px;
  height:100px;
  border-radius:4px;
  margin:10px 5px 10px 10px;
  box-shadow: 0px 0px 10px 1px rgba(191, 38, 218, 0.7);
  /* transform: translateZ(20px); */
}
/* 
.box-bg {
  background-image: linear-gradient(90deg,#ECF0F1 50%, #bdc3c7 50%);
  background-size: 50px 50px;
}
*/

  /* mbp- MBALL PROFILE CARD -- codepen.io/metaballix/pen/LYeByvB */
  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  
  .mbp-wrapper {
    width: 100%;
    height: auto;
    min-height: 100vh;
    border-radius: 10px;
    display: flex;
    /* background-image: linear-gradient(-20deg, #ff2846 0%, #6944ff 100%); */
  }
  .mbp-wrapper-2 {
    width: 100%;
    height: auto;
    padding: 50px 20px;
    padding-top: 100px;
    border-radius: 10px;
    display: flex;
    background-image: linear-gradient(-20deg, #22c777 0%, #00b6ce 100%);
  }

  @media screen and (max-width: 768px) {
    .mbp-wrapper {
      height: auto;
      min-height: 100vh;
    }
  }
  
  .profile-card {
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: auto;
    border-radius: 11px;
    background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255, 255, 255,1));
    /* box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.65); */
  }

  .profile-card.float-card-1 {
    position: absolute;
    bottom: -150px;
    right: 10%;
    max-width: 360px;
  }
  @media screen and (max-width: 576px) {
    .profile-card.float-card-1 {max-width:320px; right: auto; border-left: none; border-right: none;}
  }
  @media screen and (max-width: 400px) {
    .profile-card.float-card-1 {max-width:240px; left: 10px; }
  }

  .profile-card.active .profile-card__cnt {
    filter: blur(6px);
  }
  .profile-card.active .profile-card-message,
  .profile-card.active .profile-card__overlay {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 0.1s;
  }
  .profile-card.active .profile-card-form {
    transform: none;
    transition-delay: 0.1s;
  }
  .profile-card__img {
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    overflow: visible;
    position: relative;
    z-index: 4;
/*     border-radius: 50%;
    box-shadow: 0px 5px 38px 0px #44b9fc, 0px 0px 0px 7px rgba(105, 58, 214, 0.5); */
  }
/* 
.profile-card__img img {
  box-shadow: 0px 0px 1px 11px #1D002F inset;
  border: 5px solid #00C4CC;
  outline: #4a0b69 groove 5px;
}
*/
/* 
@media screen and (max-width: 576px) {
  .profile-card__img {
    width: 120px;
    height: 120px;
  }
} 
*/
  .profile-card__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .profile-card__cnt {
    margin-top: -35px;
    text-align: center;
    padding: 0 20px;
    padding-bottom: 40px;
    transition: all 0.3s;
  }
  .profile-card__name {
    font-weight: 700;
    font-size: 24px;
    color: #1D002F;
    margin-bottom: 15px;
  }
  .profile-card__txt {
    font-size: 1.4rem;
    font-weight: 500;
    color: #324e63;
    margin-bottom: 2rem;
  }
  .profile-card__txt strong {
    font-weight: 700;
  }
  .profile-card-loc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }
  .profile-card-loc__icon {
    display: inline-flex;
    font-size: 27px;
    margin-right: 10px;
  }
  .profile-card-inf {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 35px;
  }
  .profile-card-inf__item {
    padding: 10px 35px;
    min-width: 150px;
  }
  @media screen and (max-width: 768px) {
    .profile-card-inf__item {
      padding: 10px 20px;
      min-width: 120px;
    }
  }
  .profile-card-inf__title {
    font-weight: 700;
    font-size: 27px;
    color: #324e63;
  }
  .profile-card-inf__txt {
    font-weight: 500;
    margin-top: 7px;
  }
  .profile-card-social {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .profile-card-social__item {
    display: inline-flex;
    width: 55px;
    height: 55px;
    margin: 15px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #405de6;
    box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
    position: relative;
    font-size: 21px;
    flex-shrink: 0;
    transition: all 0.3s;
  }
  @media screen and (max-width: 768px) {
    .profile-card-social__item {
      width: 50px;
      height: 50px;
      margin: 10px;
    }
  }
  @media screen and (min-width: 768px) {
    .profile-card-social__item:hover {
      transform: scale(1.2);
    }
  }
  .profile-card-social__item.facebook {
    background: linear-gradient(45deg, #3b5998, #0078d7);
    box-shadow: 0px 4px 30px rgba(43, 98, 169, 0.5);
  }
  .profile-card-social__item.twitter {
    background: linear-gradient(45deg, #1da1f2, #0e71c8);
    box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.7);
  }
  .profile-card-social__item.instagram {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    box-shadow: 0px 4px 30px rgba(120, 64, 190, 0.6);
  }
  .profile-card-social__item.behance {
    background: linear-gradient(45deg, #1769ff, #213fca);
    box-shadow: 0px 4px 30px rgba(27, 86, 231, 0.7);
  }
  .profile-card-social__item.github {
    background: linear-gradient(45deg, #333333, #626b73);
    box-shadow: 0px 4px 30px rgba(63, 65, 67, 0.6);
  }
  .profile-card-social__item.codepen {
    background: linear-gradient(45deg, #324e63, #414447);
    box-shadow: 0px 4px 30px rgba(55, 75, 90, 0.6);
  }
  .profile-card-social__item.link {
    background: linear-gradient(45deg, #d5135a, #f05924);
    box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.6);
  }
  .profile-card-social .icon-font {
    display: inline-flex;
  }

/*   @media screen and (max-width: 576px) {
    .profile-card-ctr {
      flex-wrap: wrap;
    }
  } */
  .profile-card__button {
    background: none;
    border: none;
    /* font-family: "Quicksand", sans-serif; */
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 15px 35px;
    padding: 15px 40px;
    min-width: 201px;
    border-radius: 50px;
    min-height: 55px;
    color: #fff;
    cursor: pointer;
    backface-visibility: hidden;
    transition: all 0.3s;
  }
  @media screen and (max-width: 768px) {
    .profile-card__button {
      min-width: 170px;
      margin: 15px 25px;
    }
  }
  @media screen and (max-width: 576px) {
    .profile-card__button {
      margin: 10px 2%;
      padding: 10px 10px;
      min-width: 140px;
    }
  }
/*   @media screen and (max-width: 576px) {
    .profile-card__button {
      min-width: inherit;
      margin: 0;
      margin-bottom: 16px;
      width: 100%;
      max-width: 300px;
    }
    .profile-card__button:last-child {
      margin-bottom: 0;
    }
  } */
  .profile-card__button:focus {
    outline: none !important;
  }
  @media screen and (min-width: 768px) {
    .profile-card__button:hover {
      transform: translateY(-5px);
    }
  }
  .profile-card__button:first-child {
    margin-left: 0;
  }
  .profile-card__button:last-child {
    margin-right: 0;
  }
  .profile-card__button.button--blue {
    background: linear-gradient(45deg, #1da1f2, #0e71c8);
    box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
  }
  .profile-card__button.button--blue:hover {
    box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
  }
  .profile-card__button.button--orange {
    background: linear-gradient(45deg, #d5135a, #f05924);
    box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
  }
  .profile-card__button.button--orange:hover {
    box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
  }
  .profile-card__button.button--gray {
    box-shadow: none;
    background: #dcdcdc;
    color: #142029;
  }
  .profile-card-message {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 130px;
    padding-bottom: 100px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
  }
  .profile-card-form {
    box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 35px;
    transform: scale(0.8);
    position: relative;
    z-index: 3;
    transition: all 0.3s;
  }
  @media screen and (max-width: 768px) {
    .profile-card-form {
      max-width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 576px) {
    .profile-card-form {
      padding: 20px;
    }
  }
  .profile-card-form__bottom {
    justify-content: space-between;
    display: flex;
  }
  @media screen and (max-width: 576px) {
    .profile-card-form__bottom {
      flex-wrap: wrap;
    }
  }
  .profile-card textarea {
    width: 100%;
    resize: none;
    height: 210px;
    margin-bottom: 20px;
    border: 2px solid #dcdcdc;
    border-radius: 10px;
    padding: 15px 20px;
    color: #324e63;
    font-weight: 500;
    /* font-family: "Quicksand", sans-serif; */
    outline: none;
    transition: all 0.3s;
  }
  .profile-card textarea:focus {
    outline: none;
    border-color: #8a979e;
  }
  .profile-card__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    background: rgba(22, 33, 72, 0.35);
    border-radius: 12px;
    transition: all 0.3s;
  }


/* HONE-YOUR-XONE FEATURES */
/* 4x2 Features Grid - snippets.wrappixel.com/multiple-services-cards-section */
.xone-feat {
color: #8d97ad;
font-weight: 300;
}

.xone-feat h1, .xone-feat h2, .xone-feat h3, .xone-feat h4, .xone-feat h5, .xone-feat h6 {
color: #3e4555;
}

.xone-feat a {
    text-decoration: none;
}

.xone-feat .card-hover:hover {
    background: #2cdd9b;
    background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
    background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
    background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
    background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}
.xone-feat .card-hover:hover .bg-success-grad {
    color: #ffffff;
    text-fill-color: #ffffff;
    -webkit-text-fill-color: #ffffff;
}
.xone-feat .card-hover:hover .ser-title {
  color: #ffffff;
}
.xone-feat .bg-success-grad {
    background: #2cdd9b;
    background: -webkit-linear-gradient(legacy-direction(to right), #22c777 0%, #00b6ce 100%);
    background: -webkit-gradient(linear, left top, right top, from(#22c777), to(#00b6ce));
    background: -webkit-linear-gradient(left, #22c777 0%, #00b6ce 100%);
    background: -o-linear-gradient(left, #22c777 0%, #00b6ce 100%);
    background: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 60px;
    font-weight: 500;
}
.xone-feat .xone-feat-wrapper .card {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px -1px #01283a;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 9px;
  overflow: hidden;
}

/* xone-feat -- inverse gradient cards :: 
.xone-feat .card-inverse .card-hover:hover { background: #ffffff; }
.xone-feat .card-inverse .card-hover:hover .bg-success-grad {
  color: #2cdd9b;
  text-fill-color: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
  -webkit-text-fill-color: linear-gradient(to right, #22c777 0%, #00b6ce 100%);
}
.xone-feat .card-inverse .bg-success-grad {
  background-color: #ffffff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 60px;
  font-weight: 500;
}
.xone-feat .xone-feat-wrapper .card.card-inverse {
  background: #2cdd9b;
  background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
  background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);  box-shadow: 0 0 10px -1px #01283a;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 9px;
  overflow: hidden;
}
*/

/* xone-feat cards -- icons and svgs */
.xone-feat-icon {
  position: absolute;
  transform: initial;
  opacity: 0.32;
/*  to center div in parent ::
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); */
}
.xone-feat-svg { 
  fill: #22c777;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.xone-feat-svg:hover { fill: #FFFFFF; }
.xone-feat-svg-inverse { 
  fill: #FFFFFF;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.xone-feat-svg-inverse:hover { fill: #22c777; }

.xone-feat-icon i {
  color: linear-gradient(to top right, #22c777 0%, #00b6ce 90%);
  opacity: 0.38;
}

.xone-feat .xone-feat-wrapper .card:hover {
    transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
}

.xone-feat .btn-outline-success {
    color: #2cdd9b !important;
    background-color: transparent;
    border-color: #2cdd9b;
}

.xone-feat .btn-outline-success:hover {
        background: #2cdd9b;
    border-color: #2cdd9b;
    color: #ffffff !important;
}

.xone-feat .btn-md {
    padding: 15px 45px;
    font-size: 16px;
}

/* 3d parallax cards - codepen.io/dazulu/pen/VVZrQv */

/*
* CUSTOM SCROLLBAR - jsfiddle.net/bootstrapious/3kaxvn69
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
/* Custom Scrollbar using CSS */

/*
.custom-scrollbar-js,
.custom-scrollbar-css {
  height: 200px;
}


.custom-scrollbar-css {
  overflow-y: scroll;
}
*/

/* scrollbar width */
.custom-scrollbar-css::-webkit-scrollbar {
  height: 11px;
}

/* scrollbar track */
.custom-scrollbar-css::-webkit-scrollbar-track {
  background: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

/* scrollbar handle */
.custom-scrollbar-css::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #00d2ff;
  margin-right: 5px;
  margin-left: 5px;
  background-image: linear-gradient(to top, #00d2ff 0%, #7e37b8 100%);
}

/* mbp- Swiper Container - codepen.io/metaballix/pen/PoEeLqN */

/* #plasmaverse > showcase 2x2 (showcase snippet) :: 
.showcase {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad;
  font-weight: 300;
}
*/

.showcase h1, .showcase h2, .showcase h3, .showcase h4, .showcase h5, .showcase h6 {
  color: #3e4555;
}

.showcase h6 {
		line-height: 22px;
    font-size: 18px;
}

.showcase .font-weight-medium {
	font-weight: 500;
}

.showcase .badge {
	line-height: 14px;
}

.showcase .badge-info {
	background: #188ef4;
}

.showcase .subtitle {
    color: #8d97ad;
    line-height: 24px;
}

.showcase .card.card-shadow {
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.showcase .img-shadow {
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
}

.showcase .wrap-showcase .uneven-box {
    margin-top: 92px;
}

.showcase .btn-info-gradiant {
		background: #188ef4;
    background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
    background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
    background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.showcase .btn-info-gradiant:hover {
		background: #316ce8;
    background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
    background: -webkit-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
    background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
    background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
    background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.showcase .btn-md {
    padding: 15px 45px;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
		.showcase .wrap-showcase .uneven-box {
			margin-top: 0px;
	}
}

/* Timeline 2 - metaballix roadmap CSS extensions :: 
.roadmap { --bs-rows: 5; --bs-columns: 5; }
.tl-roadmap .list-group { padding-bottom: 5px; }
.tl-roadmap .list-group-item{ background-color: rgba(255,255,255,0.75); }
.tl-roadmap .tl-phase1 .list-group-item:hover{ background-color: rgba(255, 197, 142, 0.75); }
.tl-roadmap .tl-phase1 .list-group-item:active{ background-color: rgba(245, 156, 121, 0.75); }
.tl-roadmap .tl-phase2 .list-group-item:hover{ background-color: rgba(142, 255, 185, 0.75); }
.tl-roadmap .tl-phase2 .list-group-item:active{ background-color: rgba(110, 228, 147, 0.75); }
.tl-roadmap .tl-phase3 .list-group-item:hover{ background-color: rgba(164, 242, 253, 0.75); }
.tl-roadmap .tl-phase3 .list-group-item:active{ background-color: rgba(119, 218, 228, 0.75); }
.tl-roadmap .tl-phase4 .list-group-item:hover{ background-color: rgba(253, 164, 223, 0.75); }
.tl-roadmap .tl-phase4 .list-group-item:active{ background-color: rgba(228, 119, 182, 0.75); }
.tl-roadmap .tl-phase5 .list-group-item:hover{ background-color: rgba(225, 164, 253, 0.75); }
.tl-roadmap .tl-phase5 .list-group-item:active{ background-color: rgba(206, 119, 228, 0.75); }
@media screen and (max-width: 992px){
    .tl-roadmap .list-group { padding-bottom: 15px; }
}
*/

/* Roadmap1 (testimonial6 - snippets.wrappixel.com/testimonials-tab-style) */
/* 
.roadmap1 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.roadmap1 h1,
.roadmap1 h2,
.roadmap1 h3,
.roadmap1 h4,
.roadmap1 h5,
.roadmap1 h6 {
  color: #3e4555;
}

.roadmap1 .font-weight-medium {
  font-weight: 500;
}

.roadmap1 h5 {
    line-height: 22px;
    font-size: 18px;
}

.roadmap1 .subtitle {
  color: #8d97ad;
  line-height: 24px;
	font-size: 16px;
} 

.roadmap1 .rmv2 { border-right: 1px solid rgba(236, 236, 236, 0.8); }
*/

.roadmap1 .rmv2 .nav-link {
  border-radius: 0px;
  margin: 8px -2px 8px 0;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px; 
}

.roadmap1 .rmv2 .nav-link img {
  width: 70px;
  opacity: 0.65;
}

.roadmap1 .rmv2 .nav-link.active img {
  opacity: 1;
}

.roadmap1 .btn-danger {
    background: #ff4d7e !important;
    border: 1px solid #ff4d7e !important;
}

.roadmap1 .btn-md {
    padding: 18px 0px;
    width: 60px;
    height: 60px;
    font-size: 20px;
}

@media (max-width: 768px) {
	.roadmap1 .rmv2 .nav-link {
    margin: 0 11px -2px 0;
    padding: 11px 22px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-right: 3px solid rgba(255, 255, 255, 0);
	}
  .roadmap1 .rmv2 .nav-link:not(.complete) {
    box-shadow: 0 7px 11px rgba(228,228,228, 0.25), 0 0 15px rgba(0,0,0, 0.92); 
    border-bottom: 1px solid #747474; }

	.roadmap1 .rmv2 {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-right: 0px solid rgba(120, 130, 140, 0.13);
    border-bottom: 0px solid rgba(120, 130, 140, 0.13);
    margin-bottom: 22px;
    justify-content: center; 
	}
	.roadmap1 .rmv2 .nav-link img {
    width: 40px;
	}
  .roadmap1 .rmv2 .nav-link:nth-child(1).complete { border-right-width: 0px !important; border-bottom: 4px solid #DC8C3E; }
  .roadmap1 .rmv2 .nav-link:nth-child(2).complete { border-right-width: 0px !important; border-bottom: 4px solid #8BD33B; }
  .roadmap1 .rmv2 .nav-link:nth-child(3).complete { border-right-width: 0px !important; border-bottom: 4px solid #69D4D7; }
  .roadmap1 .rmv2 .nav-link:nth-child(4).complete { border-right-width: 0px !important; border-bottom: 4px solid #CF21C2; }
  .roadmap1 .rmv2 .nav-link:nth-child(5).complete { border-right-width: 0px !important; border-bottom: 4px solid #7C2AE8; }
/* 
  .roadmap1 .rmv2 .nav-link:nth-child(1).active { border-bottom: 4px solid #DC8C3E; box-shadow: 0 0 8px -1px #DC8C3E; }
  .roadmap1 .rmv2 .nav-link:nth-child(2).active { border-bottom: 4px solid #8BD33B; box-shadow: 0 0 8px -1px #8BD33B; }
  .roadmap1 .rmv2 .nav-link:nth-child(3).active { border-bottom: 4px solid #69D4D7; box-shadow: 0 0 8px -1px #69D4D7; }
  .roadmap1 .rmv2 .nav-link:nth-child(4).active { border-bottom: 4px solid #CF21C2; box-shadow: 0 0 8px -1px #CF21C2; }
  .roadmap1 .rmv2 .nav-link:nth-child(5).active { border-bottom: 4px solid #7C2AE8; box-shadow: 0 0 8px -1px #7C2AE8; }
*/
}

@media (max-width: 576px) {
  .roadmap1 .rmv2 .nav-link {
    margin-bottom: 5px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 5px 15px !important;
  }
}

/* 
.roadmap1 .rm-phase-heading {
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 600;
  font-style: none;
  padding-bottom: 5px;
} */

.roadmap1 .rm-phase-heading {
  font-weight: 700;
  text-shadow: 1px 1px 1px #222222; 
  background: linear-gradient(-120deg, rgba(255,255,255,0.11), rgba(255,255,255,0.47));
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  /* margin-bottom: 0; */
}
.roadmap1 .rm-title {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#rm-p1-1 .rm-title { background: linear-gradient(to right,#FC424A,#DC8C3E); }
#rm-p2-1 .rm-title { background: linear-gradient(to right,#3BCD59,#8BD33B); }
#rm-p3-1 .rm-title { background: linear-gradient(to right,#10A5D7,#69D4D7); }
#rm-p4-1 .rm-title { background: linear-gradient(to right,#E31F9A,#CF21C2); }
#rm-p5-1 .rm-title { background: linear-gradient(to right,#7111A1,#7C2AE8); }
#rm-p1-1 .rm-phase-heading { color: #fc5e42; }
#rm-p2-1 .rm-phase-heading { color: #3BCD59; }
#rm-p3-1 .rm-phase-heading { color: #10A5D7; }
#rm-p4-1 .rm-phase-heading { color: #E31F9A; }
#rm-p5-1 .rm-phase-heading { color: #7111A1; }


.roadmap1 .rmv2 .nav-link.complete { background: linear-gradient(120deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.38)) 100%; }
.roadmap1 .rmv2 .nav-link.active { background: linear-gradient(120deg, rgba(255,255,255,0.25) -10%, rgba(255,255,255,0.65)) 100%; }

.roadmap1 .rmv2 .nav-link:not(.complete) {
  background-color: rgba(121, 121, 121, 0.25); 
}
@media screen and (min-width: 768px) {
.roadmap1 .rmv2 .nav-link:not(.complete) {
  border-right: 1px solid #747474;
  box-shadow: 7px 0 11px rgba(228,228,228, 0.25), 0 0 15px rgba(0,0,0, 0.92); }
}

.roadmap1 .rmv2 .nav-link span { color: rgba(255,255,255,0.5); }
.roadmap1 .rmv2 .nav-link:nth-child(1).active span,
.roadmap1 .rmv2 .nav-link:nth-child(1).complete span { color: #DC8C3E; }
.roadmap1 .rmv2 .nav-link:nth-child(2).active span,
.roadmap1 .rmv2 .nav-link:nth-child(2).complete span { color: #8BD33B; }
.roadmap1 .rmv2 .nav-link:nth-child(3).active span,
.roadmap1 .rmv2 .nav-link:nth-child(3).complete span { color: #69D4D7; }
.roadmap1 .rmv2 .nav-link:nth-child(4).active span,
.roadmap1 .rmv2 .nav-link:nth-child(4).complete span { color: #CF21C2; }
.roadmap1 .rmv2 .nav-link:nth-child(5).active span,
.roadmap1 .rmv2 .nav-link:nth-child(5).complete span { color: #7C2AE8; }

.roadmap1 .rmv2 .nav-link:nth-child(1).active,
.roadmap1 .rmv2 .nav-link:nth-child(1).complete { color: #DC8C3E; border-right: 3px solid #DC8C3E; }
.roadmap1 .rmv2 .nav-link:nth-child(2).active,
.roadmap1 .rmv2 .nav-link:nth-child(2).complete { color: #8BD33B; border-right: 3px solid #8BD33B; }
.roadmap1 .rmv2 .nav-link:nth-child(3).active,
.roadmap1 .rmv2 .nav-link:nth-child(3).complete { color: #69D4D7; border-right: 3px solid #69D4D7; }
.roadmap1 .rmv2 .nav-link:nth-child(4).active,
.roadmap1 .rmv2 .nav-link:nth-child(4).complete { color: #CF21C2; border-right: 3px solid #CF21C2; }
.roadmap1 .rmv2 .nav-link:nth-child(5).active,
.roadmap1 .rmv2 .nav-link:nth-child(5).complete { color: #7C2AE8; border-right: 3px solid #7C2AE8; }

.roadmap1 .rmv2 .nav-link:nth-child(1).active { border-bottom: 4px solid #DC8C3E; box-shadow: 0 0 8px -1px #DC8C3E; }
.roadmap1 .rmv2 .nav-link:nth-child(2).active { border-bottom: 4px solid #8BD33B; box-shadow: 0 0 8px -1px #8BD33B; }
.roadmap1 .rmv2 .nav-link:nth-child(3).active { border-bottom: 4px solid #69D4D7; box-shadow: 0 0 8px -1px #69D4D7; }
.roadmap1 .rmv2 .nav-link:nth-child(4).active { border-bottom: 4px solid #CF21C2; box-shadow: 0 0 8px -1px #CF21C2; }
.roadmap1 .rmv2 .nav-link:nth-child(5).active { border-bottom: 4px solid #7C2AE8; box-shadow: 0 0 8px -1px #7C2AE8; }

.roadmap1 .rm-content .list-group {
  /* margin-top: 0; */
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: rgba(255,255,255,0.11);
}
.roadmap1 .rm-content .list-group-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.roadmap1 .rm-content .list-group-item p {
  padding-right: 1rem;
}
.roadmap1 .rm-content .list-group-item span {
  padding-top: 0.5rem;
}

.roadmap1 .rm-content .description {
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 200;
}

.roadmap1 #rm-phase1 .list-group-item:hover{ background-color: rgba(255, 197, 142, 0.75); }
.roadmap1 #rm-phase1 .list-group-item:active{ background-color: rgba(245, 156, 121, 0.75); }
.roadmap1 #rm-phase2 .list-group-item:hover{ background-color: rgba(142, 255, 185, 0.75); }
.roadmap1 #rm-phase2 .list-group-item:active{ background-color: rgba(110, 228, 147, 0.75); }
.roadmap1 #rm-phase3 .list-group-item:hover{ background-color: rgba(164, 242, 253, 0.75); }
.roadmap1 #rm-phase3 .list-group-item:active{ background-color: rgba(119, 218, 228, 0.75); }
.roadmap1 #rm-phase4 .list-group-item:hover{ background-color: rgba(253, 164, 223, 0.75); }
.roadmap1 #rm-phase4 .list-group-item:active{ background-color: rgba(228, 119, 182, 0.75); }
.roadmap1 #rm-phase5 .list-group-item:hover{ background-color: rgba(225, 164, 253, 0.75); }
.roadmap1 #rm-phase5 .list-group-item:active{ background-color: rgba(206, 119, 228, 0.75); }